import {
  Button,
  ButtonColor,
  ToastDuration,
  ToastType,
  useToast,
} from '@leland-dev/leland-ui-library';
import React, {
  type FormEvent,
  type ReactElement,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import { useLogin } from '../../context/AuthContext';
import { getFirstErrorMessage } from '../../utils/apollo';
import { LEGAL_URL } from '../../utils/constants';
import { logException } from '../../utils/exception';
import AuthCard from '../auth/AuthCard';
import Input from '../inputs/Input';

import { useLoginFormV2ResetPasswordMutation } from './__generated-gql-types__/LoginFormV2.generated';

const REGISTER_OPTIONS = Object.freeze({
  EMAIL: {
    required: {
      value: true,
      message: 'Email is required',
    },
    // Delegate email pattern matching to browser via type=email
  },
});

// TODO: Refactor and extend more flexibly
interface ErrorsHash {
  errors?: Record<
    string,
    {
      message: string;
    }
  >;
}
const buildErrorsHash = (key: string, message: string): ErrorsHash => {
  return {
    errors: {
      [key]: { message },
    },
  };
};

type ModalProps =
  | {
      isModal: true;
      setMode: React.Dispatch<React.SetStateAction<'login' | 'signup'>>;
    }
  | {
      isModal?: false;
      setMode?: never;
    };

type LoginFormV2Props = ModalProps & {
  className?: string;
  onApplicantModalLogin?: () => void;
  onCoachModalLogin?: () => void;
  returnTo: string;
};

export default function LoginFormV2({
  className = '',
  onApplicantModalLogin,
  onCoachModalLogin,
  returnTo,
  ...modalProps
}: LoginFormV2Props): ReactElement {
  const { showToast } = useToast();
  const { requestVerificationCode } = useLogin();
  const [resetPassword] = useLoginFormV2ResetPasswordMutation();
  const [email, setEmail] = useState('');
  const [step, setStep] = useState<
    | 'login'
    | 'passwordless'
    | 'resetPassword'
    | 'passwordlessSuccess'
    | 'resetPasswordSuccess'
  >('login');
  const h1ClassName = `font-medium ${
    modalProps.isModal ? 'text-2xl' : 'text-4xl'
  }`;

  const handlePasswordlessStepSubmit = async (
    email: string,
  ): Promise<ErrorsHash | undefined> => {
    try {
      await requestVerificationCode(email);
      setEmail(email);
      setStep('passwordlessSuccess');
    } catch (e) {
      return buildErrorsHash(
        'email',
        (Array.isArray(e) ? getFirstErrorMessage(e) : null) ??
          // Most likely 4xx server error that is difficult to act on. Returning default error message
          'We ran into an error while requesting a verification link. Please check your email and try again.',
      );
    }
  };

  const handleChangeToResetPassword = (email: string) => {
    setEmail(email);
    setStep('resetPassword');
  };

  const handleResetPasswordStepSubmit = async (
    email: string,
  ): Promise<ErrorsHash | undefined> => {
    try {
      await resetPassword({ variables: { email } });
      setEmail(email);
      if (step == 'resetPasswordSuccess') {
        // Resend email
        showToast({
          type: ToastType.INFO,
          duration: ToastDuration.NORMAL,
          message: 'Reset password email sent.',
        });
      } else {
        setStep('resetPasswordSuccess');
      }
    } catch (e) {
      return buildErrorsHash(
        'email',
        (Array.isArray(e) ? getFirstErrorMessage(e) : null) ??
          'We ran into an error while sending a password reset email. Please check your email and try again.',
      );
    }
  };

  if (step === 'login') {
    return (
      <LoginStep
        className={className}
        h1ClassName={h1ClassName}
        onApplicantModalLogin={onApplicantModalLogin}
        onCoachModalLogin={onCoachModalLogin}
        onPasswordlessClick={() => setStep('passwordless')}
        returnTo={returnTo}
        {...modalProps}
      />
    );
  }

  if (step === 'passwordless') {
    return (
      <PasswordlessStep
        email={email}
        className={className}
        h1ClassName={h1ClassName}
        onSubmit={handlePasswordlessStepSubmit}
        onResetPassword={handleChangeToResetPassword}
      />
    );
  }

  if (step === 'resetPassword') {
    return (
      <ResetPasswordStep
        email={email}
        className={className}
        h1ClassName={h1ClassName}
        onSubmit={handleResetPasswordStepSubmit}
      />
    );
  }

  if (step === 'passwordlessSuccess') {
    return (
      <PasswordlessSuccessStep
        email={email}
        className={className}
        h1ClassName={h1ClassName}
        onReEnterEmail={() => setStep('passwordless')}
      />
    );
  }

  if (step === 'resetPasswordSuccess') {
    return (
      <ResetPasswordSuccessStep
        email={email}
        className={className}
        h1ClassName={h1ClassName}
        onResendEmail={() => handleResetPasswordStepSubmit(email)}
        onBackToLogin={() => setStep('login')}
      />
    );
  }

  // Unexpected state
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  logException(`Attempted to render invalid login form for step = ${step}`);
  return <></>;
}

type LoginStepProps = ModalProps & {
  className: string;
  h1ClassName: string;
  onApplicantModalLogin?: () => void;
  onCoachModalLogin?: () => void;
  onPasswordlessClick: () => void;
  returnTo: string;
};

const LoginStep = ({
  className,
  h1ClassName,
  isModal,
  setMode,
  onApplicantModalLogin,
  onCoachModalLogin,
  onPasswordlessClick,
  returnTo,
}: LoginStepProps): ReactElement => {
  return (
    <div className={`${className}`}>
      <h1 className={`${h1ClassName}`}>Log in to your Leland account</h1>
      {!isModal && (
        <p className="mt-2.5 text-lg text-gray-500">
          Both coaches & customers can log in here.
        </p>
      )}
      <AuthCard
        screenHint="login"
        className="mt-8"
        returnTo={returnTo}
        isModal={isModal}
        onApplicantModalLogin={onApplicantModalLogin}
        onCoachModalLogin={onCoachModalLogin}
      />
      <div className="mt-5 flex place-content-center">
        <p className="text-leland-gray-light">Don’t know your credentials?</p>
        <button
          className="ml-1 font-medium text-leland-primary"
          onClick={onPasswordlessClick}
        >
          Get help logging in
        </button>
      </div>
      {isModal ? (
        <p className="mt-4 text-leland-gray-light">
          New to Leland?{' '}
          <button
            className="link link--primary font-medium"
            onClick={() => setMode('signup')}
          >
            Create an account
          </button>
        </p>
      ) : null}
      <div className="mt-4 text-leland-gray-extra-light">
        <a
          className="link px-2"
          href={`${LEGAL_URL}/terms`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          Terms & Conditions
        </a>
        ·
        <a
          className="link px-2"
          href={`${LEGAL_URL}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

interface PasswordlessStepProps {
  email: string;
  className: string;
  h1ClassName: string;
  onSubmit: (email: string) => Promise<ErrorsHash | undefined>;
  onResetPassword: (email: string) => void;
}

const PasswordlessStep = ({
  email,
  className,
  h1ClassName,
  onSubmit,
  onResetPassword,
}: PasswordlessStepProps): ReactElement => {
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({ defaultValues: { email } });
  useEffect(() => setFocus('email'), [setFocus]);

  // TODO: Create generic wrapper to avoid concurrent submissions and apply to other instances
  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (isSubmitting) return;

    await handleSubmit(async ({ email }) => {
      const resp = await onSubmit(email);

      if (resp?.errors?.email) {
        setError('email', resp.errors.email);
      }
    })(event);
  };

  return (
    <div className={`${className}`}>
      <h1 className={`${h1ClassName}`}>Log in without your password</h1>
      <p className="mb-8 mt-2.5 text-lg text-leland-gray-light">
        We’ll email you a link that will log you in.
      </p>
      <form
        data-form-name="passwordless-login-form"
        className="grid gap-3"
        onSubmit={handleFormSubmit}
      >
        {/* Email */}
        <Input
          label="Email"
          type="email"
          placeholder="Email"
          labelIsHidden
          register={register('email', REGISTER_OPTIONS.EMAIL)}
          error={errors.email}
        />
        <Button
          type="submit"
          label="Continue"
          data-control-name="submit-passwordless-login-form"
          buttonColor={ButtonColor.PRIMARY}
          disabled={isSubmitting}
        />
        <div className="relative flex w-full items-center justify-center">
          <hr className="my-8 h-px w-full border-0 bg-gray-200" />
          <span className="absolute left-1/2 -translate-x-1/2 bg-white px-2 text-lg text-leland-gray-light">
            or
          </span>
        </div>
        <Button
          label="Reset your password"
          buttonColor={ButtonColor.WHITE}
          onClick={() => onResetPassword(getValues().email)}
        />
      </form>
    </div>
  );
};

interface ResetPasswordStepProps {
  email: string;
  className: string;
  h1ClassName: string;
  onSubmit: (email: string) => Promise<ErrorsHash | undefined>;
}

const ResetPasswordStep = ({
  email,
  className,
  h1ClassName,
  onSubmit,
}: ResetPasswordStepProps): ReactElement => {
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({ defaultValues: { email } });
  useEffect(() => setFocus('email'), [setFocus]);

  // TODO: Create generic wrapper to avoid concurrent submissions and apply to other instances
  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (isSubmitting) return;

    await handleSubmit(async ({ email }) => {
      const resp = await onSubmit(email);

      if (resp?.errors?.email) {
        setError('email', resp.errors.email);
      }
    })(event);
  };

  return (
    <div className={`${className}`}>
      <h1 className={`${h1ClassName}`}>Reset your password</h1>
      <p className="mb-8 mt-2.5 text-lg text-leland-gray-light">
        Enter the email address you use to log in to Leland.
      </p>
      <form
        data-form-name="reset-password-form"
        className="grid gap-3"
        onSubmit={handleFormSubmit}
      >
        {/* Email */}
        <Input
          label="Email"
          type="email"
          placeholder="Email"
          labelIsHidden
          register={register('email', REGISTER_OPTIONS.EMAIL)}
          error={errors.email}
        />
        <Button
          data-control-name="submit-reset-password-form"
          label="Get a reset link"
          buttonColor={ButtonColor.PRIMARY}
          disabled={isSubmitting}
          type="submit"
        />
      </form>
    </div>
  );
};

interface PasswordlessSuccessStepProps {
  email: string;
  className: string;
  h1ClassName: string;
  onReEnterEmail: () => void;
}

const PasswordlessSuccessStep = ({
  email,
  className,
  h1ClassName,
  onReEnterEmail,
}: PasswordlessSuccessStepProps): ReactElement => {
  return (
    <div className={`${className}`}>
      <h1 className={`${h1ClassName}`}>Check your email!</h1>
      <p className="mb-6 mt-2.5 text-leland-gray-light">
        To login password-free, tap the link in the email we sent to{' '}
        <b>{email}</b>.
      </p>
      <div className="flex place-content-center">
        <p className="text-leland-gray-light"> Wrong email address? Please </p>
        <button
          className="ml-1 font-medium text-leland-primary"
          onClick={onReEnterEmail}
        >
          re-enter email address
        </button>
        <p>.</p>
      </div>
    </div>
  );
};

interface ResetPasswordSuccessStepProps {
  email: string;
  className: string;
  h1ClassName: string;
  onResendEmail: () => Promise<ErrorsHash | undefined>;
  onBackToLogin: () => void;
}

const ResetPasswordSuccessStep = ({
  email,
  className,
  h1ClassName,
  onResendEmail,
  onBackToLogin,
}: ResetPasswordSuccessStepProps): ReactElement => {
  return (
    <div className={`${className}`}>
      <h1 className={`${h1ClassName}`}>Check your email.</h1>
      <p className="my-2.5 text-leland-gray-light">
        We sent a password reset link to <b>{email}</b>.
      </p>
      <div className="mb-8 flex place-content-center">
        <p className="text-leland-gray-light"> Didn’t receive an email? </p>
        <button
          className="ml-1 font-medium text-leland-primary"
          onClick={onResendEmail}
        >
          Click here to resend.
        </button>
      </div>
      <Button
        label="Back to login"
        buttonColor={ButtonColor.WHITE}
        onClick={onBackToLogin}
      />
    </div>
  );
};

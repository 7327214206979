import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { useEffect } from 'react';

import AuthPageLayout from '../components/AuthPageLayout';
import LoginFormV2 from '../components/forms/LoginFormV2';
import Page from '../components/page/Page';
import { useAuth } from '../context/AuthContext';
import {
  APPLICANT_SITE_URL,
  COACH_SITE_URL,
  isServer,
  RETURN_TO_KEY,
} from '../utils/constants';
import { LelandLocalStorage } from '../utils/storage';
import { getUrlObject } from '../utils/url';

const DEFAULT_REDIRECT_URL = '/';

const CANONICAL_LOGIN_LINK = `${COACH_SITE_URL}/login`;

const Login: React.FC = () => {
  const router = useRouter();
  const { currentUser, isLoadingUser } = useAuth();
  const redirectUrl =
    (router.query.redirect_url as Optional<string>) ?? DEFAULT_REDIRECT_URL;
  if (!isServer) {
    LelandLocalStorage.setItem(RETURN_TO_KEY, redirectUrl);
  }

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    // Redirect if already logged in
    if (currentUser?.coach != null) {
      void router.push('/');
    } else if (currentUser?.applicant != null) {
      void router.push(APPLICANT_SITE_URL);
    }
  }, [currentUser?.applicant, currentUser?.coach, isLoadingUser, router]);

  return (
    <Page title="Log in" canonicalLink={CANONICAL_LOGIN_LINK}>
      <AuthPageLayout
        topRightLink={
          <div className="text-right">
            <p>New to Leland?</p>
            <Link
              href={getUrlObject('/signup', router.query)}
              className="link link--primary font-medium"
            >
              Create an account
            </Link>
          </div>
        }
      >
        <LoginFormV2 className="w-full text-center" returnTo={redirectUrl} />
      </AuthPageLayout>
    </Page>
  );
};

export default Login;

// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginFormV2ResetPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type LoginFormV2ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export const LoginFormV2ResetPasswordDocument = gql`
  mutation LoginFormV2_ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
export type LoginFormV2ResetPasswordMutationFn = Apollo.MutationFunction<
  LoginFormV2ResetPasswordMutation,
  LoginFormV2ResetPasswordMutationVariables
>;

/**
 * __useLoginFormV2ResetPasswordMutation__
 *
 * To run a mutation, you first call `useLoginFormV2ResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginFormV2ResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginFormV2ResetPasswordMutation, { data, loading, error }] = useLoginFormV2ResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginFormV2ResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginFormV2ResetPasswordMutation,
    LoginFormV2ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginFormV2ResetPasswordMutation,
    LoginFormV2ResetPasswordMutationVariables
  >(LoginFormV2ResetPasswordDocument, options);
}
export type LoginFormV2ResetPasswordMutationHookResult = ReturnType<
  typeof useLoginFormV2ResetPasswordMutation
>;
export type LoginFormV2ResetPasswordMutationResult =
  Apollo.MutationResult<LoginFormV2ResetPasswordMutation>;
export type LoginFormV2ResetPasswordMutationOptions =
  Apollo.BaseMutationOptions<
    LoginFormV2ResetPasswordMutation,
    LoginFormV2ResetPasswordMutationVariables
  >;
